import { Currency } from "@pancakeswap/sdk";
import { ArrowForwardIcon, Box, Flex, Text } from "@pancakeswap/uikit";
import { CurrencyLogo } from "../components/CurrencyLogo";

interface TokenTransferInfoProps {
  symbolA?: string;
  symbolB?: string;
  amountA: string;
  amountB: string;
  currencyA?: Currency;
  currencyB?: Currency;
}

const TokenTransferInfo: React.FC<TokenTransferInfoProps> = ({
  symbolA,
  symbolB,
  amountA,
  amountB,
  currencyA,
  currencyB,
}) => {
  return (
    <Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box mr="4px">
          <CurrencyLogo size="20px" currency={currencyA} />
        </Box>
        <Text bold fontSize="14px">{`${amountA} ${symbolA}`}</Text>
      </Flex>
      <Box m="0 5px">
        <ArrowForwardIcon />
      </Box>
      <Flex alignItems="center" justifyContent="center">
        <Box mr="4px">
          <CurrencyLogo size="20px" currency={currencyB} />
        </Box>
        <Text bold fontSize="14px">{`${amountB} ${symbolB}`}</Text>
      </Flex>
    </Flex>
  );
};

export default TokenTransferInfo;
