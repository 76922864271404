import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.35 15.8261C7.6825 16.2609 7.875 16.8 7.875 17.3913C7.875 18.8348 6.7025 20 5.25 20C3.7975 20 2.625 18.8348 2.625 17.3913C2.625 15.9478 3.7975 14.7826 5.25 14.7826C5.635 14.7826 5.99375 14.8609 6.32625 15.0087L7.56 13.4696C6.755 12.5739 6.43125 11.3913 6.60625 10.2609L4.83 9.66957C4.3575 10.3913 3.5525 10.8696 2.625 10.8696C1.1725 10.8696 0 9.70435 0 8.26087C0 6.81739 1.1725 5.65217 2.625 5.65217C4.0775 5.65217 5.25 6.81739 5.25 8.26087C5.25 8.32174 5.25 8.38261 5.24125 8.44348L7.0175 9.03478C7.5775 7.98261 8.61 7.21739 9.835 7.01739V5.13913C8.715 4.84348 7.875 3.82609 7.875 2.6087C7.875 1.16522 9.0475 0 10.5 0C11.9525 0 13.125 1.16522 13.125 2.6087C13.125 3.82609 12.285 4.84348 11.1562 5.13913V7.01739C12.3813 7.21739 13.4138 7.98261 13.9738 9.03478L15.75 8.44348C15.75 8.38261 15.75 8.32174 15.75 8.26087C15.75 6.81739 16.9225 5.65217 18.375 5.65217C19.8275 5.65217 21 6.81739 21 8.26087C21 9.70435 19.8275 10.8696 18.375 10.8696C17.4475 10.8696 16.6425 10.3913 16.17 9.67826L14.3938 10.2696C14.5688 11.3913 14.2537 12.5739 13.44 13.4783L14.6737 15.0174C15.0063 14.8609 15.365 14.7826 15.75 14.7826C17.2025 14.7826 18.375 15.9478 18.375 17.3913C18.375 18.8348 17.2025 20 15.75 20C14.2975 20 13.125 18.8348 13.125 17.3913C13.125 16.8 13.3175 16.2609 13.65 15.8261L12.4162 14.287C11.235 14.9391 9.7825 14.9478 8.5925 14.287L7.35 15.8261Z" />
    </Svg>
  );
};

export default Icon;
