import { PancakeTheme } from '@pancakeswap/uikit'
import { createGlobalStyle } from 'styled-components'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: ${({ theme }) => theme.colors.primary};
    --text-color: ${({ theme }) => theme.colors.text};
    --line: rgba(255, 255, 255, 0.5);
    --black-color: rgba(0, 0, 0, 1);
    --base-bg: #737373;
  }

  * {
    font-family: 'Poppins', sans-serif ;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: ${({ theme }) => theme.colors.backgroundAlt};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }
`

export default GlobalStyle
