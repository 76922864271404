/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from "react";
import styled from "styled-components";
import isTouchDevice from "../../util/isTouchDevice";
import { Box, Flex } from "../Box";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { DropdownMenuItemType } from "../DropdownMenu/types";
import MenuItem from "../MenuItem/MenuItem";
// import { LinkOpen } from "../Svg";
import { MenuItemsProps } from "../MenuItems/types";

const MenuItemsMobile: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
  items = [],
  activeItem,
  activeSubItem,
  ...props
}) => {
  return (
    <StyledWrapper {...props} ml="0px">
      {items.map(({ label, items: menuItems = [], href, icon, disabled, isComingSoon, typeHref }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
        const isActive = activeItem === href;
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
        const isExternal = typeHref === DropdownMenuItemType.EXTERNAL_LINK;
        const Icon = icon;

        return (
          <DropdownMenu
            key={`${label}#${href}`}
            items={menuItems}
            py={1}
            activeItem={activeSubItem}
            isDisabled={disabled}
            mx={["8px", "", "", "", "", "", "16px"]}
          >
            <MenuItem
              {...linkProps}
              isActive={isActive}
              statusColor={statusColor}
              isDisabled={disabled}
              $isExternal={isExternal}
            >
              <Flex flexDirection="column" style={{ gap: "6px" }}>
                {icon && icon && createElement(Icon as any, { color: isActive ? "primary" : "black", width: "25px" })}

                {label}
              </Flex>

              {isComingSoon && (
                <Box
                  position="absolute"
                  left="50%"
                  bottom="-4px"
                  style={{
                    fontSize: "10px",
                    color: "#D1FAE5",
                    whiteSpace: "nowrap",
                    transform: "translateX(-50%)",
                  }}
                >
                  (Coming Soon)
                </Box>
              )}

              {/* {isExternal ? (
                <>
                  <Box
                    position="absolute"
                    right="-5px"
                    top="5px"
                    // style={{
                    //   transform: "translateX(-50%)",
                    // }}
                  >
                    <LinkOpen width="10px" />
                  </Box>
                </>
              ) : null} */}
            </MenuItem>
          </DropdownMenu>
        );
      })}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Flex).attrs({ justifyContent: "space-between", alignItems: "center" })`
  max-width: 100%;
  width: 100%;
  min-height: 70px;
  overflow-x: scroll;
  background-color: white;

  position: fixed;
  bottom: 0;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: unset;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: unset;
  }
`;
export default memo(MenuItemsMobile);
