import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 34 22" {...props}>
      <mask
        id="mask0_6349_4338"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="19"
        y="5"
        width="15"
        height="17"
      >
        <path d="M19.0547 5.76953H33.1913V21.75H19.0547V5.76953Z" fill="white" />
      </mask>
      <g mask="url(#mask0_6349_4338)">
        <path
          d="M33.1925 5.85352V18.1038C33.1925 21.9404 30.4179 22.1653 28.5604 21.3506C27.7641 21.0013 26.7177 20.3546 25.9694 19.8516L19.1172 15.2679L25.2167 11.1875L33.1925 5.85352Z"
          fill="url(#paint0_linear_6349_4338)"
        />
      </g>
      <mask
        id="mask1_6349_4338"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="3"
        width="18"
        height="14"
      >
        <path d="M16.5938 3.41406H33.1889V16.2189H16.5938V3.41406Z" fill="white" />
      </mask>
      <g mask="url(#mask1_6349_4338)">
        <path
          d="M33.1893 4.52159V5.8549L20.3668 16.1444L16.5938 13.5886L23.1667 9.19177L31.4714 3.63806C32.2009 3.14867 33.1893 3.65727 33.1893 4.52159Z"
          fill="#705215"
        />
      </g>
      <mask
        id="mask2_6349_4338"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="34"
        height="22"
      >
        <path d="M0 0.75H33.1902V21.6476H0V0.75Z" fill="white" />
      </mask>
      <g mask="url(#mask2_6349_4338)">
        <path
          d="M14.5027 6.27636L5.80341 1.34328C3.34167 -0.0536547 0 1.4093 0 3.88344V5.50886H0.00200076V18.4954C0.00200076 20.9699 3.34367 22.4325 5.80581 21.0356L6.5769 20.5978L16.5955 14.918H16.5975L23.1704 11.1902L31.5756 6.42521C32.5864 5.85099 33.191 4.89983 33.191 3.88504C33.191 1.4109 29.8494 -0.052054 27.3876 1.34488L18.6907 6.27636C17.4274 6.99223 15.766 6.99223 14.5027 6.27636Z"
          fill="url(#paint1_linear_6349_4338)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6349_4338"
          x1="26.1549"
          y1="5.85352"
          x2="26.1549"
          y2="21.7344"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.255" stop-color="#E0A42A" />
          <stop offset="1" stop-color="#7A5917" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6349_4338"
          x1="16.5955"
          y1="0.800781"
          x2="16.5955"
          y2="21.5781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0A42A" />
          <stop offset="1" stop-color="#7A5917" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
