import { css, styled } from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  text-align: center;

  /* ${({ $isActive, $variant, theme }) =>
    $isActive &&
    $variant === "subMenu" &&
    `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `}; */
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;

  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary : "black")};
  background: transparent;

  font-size: 16px;
  white-space: nowrap;
  font-weight: ${({ $isActive }) => ($isActive ? "600" : "400")};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "inherit")};
  border-radius: 8px;

  ${({ $statusColor, theme }) =>
    $statusColor &&
    `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

  ${({ $variant }) =>
    $variant === "default"
      ? css`
          padding: 0 12px;
          height: 44px;
        `
      : $variant === "subMenu"
      ? css`
          padding: 0 12px;
          height: 44px;
        `
      : css`
          padding-left: 4px;
          padding-right: 4px;

          height: 42px;
        `}

  ${({ theme }) => theme.mediaQueries.md} {
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary : theme.colors.text)};
  }
`;

export default StyledMenuItem;
