import { ChainId, chains } from '../chains'

// ADD CHAIN
export const chainNames: Partial<Record<ChainId, string>> = chains.reduce((acc, chain) => {
  if (![ChainId.ZETA, ChainId.ZETA_TESTNET, ChainId.BSC, ChainId.BSC_TESTNET, ChainId.SHAPE_TESTNET].includes(chain.id))
    return acc
  return { ...acc, [chain.id]: chain.name }
}, {})

// ADD CHAIN
export const defiLlamaChainNames: Record<ChainId, string> = {
  [ChainId.ZETA]: '',
  [ChainId.ZETA_TESTNET]: '',
  [ChainId.ZULU_TESTNET]: '',
}
