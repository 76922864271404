import {
  ChainId,
  duckTestnet,
  morphTestnet,
  shapeTestnet,
  storyTestnet,
  zeta,
  zetaTestnet,
  zuluTestnet,
  shape,
  worldChain,
} from '@sdk/constants'

// ADD CHAIN
export const SERVER_NODES = {
  [ChainId.ZETA]: [
    'https://zetachain-mainnet-archive.allthatnode.com:8545',
    'https://zetachain-mainnet-rpc.allthatnode.com:8545/1Z8elL21s0HDJiYPTduQvpSXNP7mxj3x',
    ...zeta.rpcUrls.default.http,
  ],
  [ChainId.ZULU_TESTNET]: [...zuluTestnet.rpcUrls.default.http],
  [ChainId.ZETA_TESTNET]: [
    'https://zetachain-athens-evm.blockpi.network/v1/rpc/public',
    'https://zetachain-testnet-archive.allthatnode.com:8545/1Z8elL21s0HDJiYPTduQvpSXNP7mxj3x',
    ...zetaTestnet.rpcUrls.default.http,
  ],
  [ChainId.SHAPE_TESTNET]: [...shapeTestnet.rpcUrls.default.http],
  [ChainId.SHAPE]: [...shape.rpcUrls.default.http],
  [ChainId.STORY_TESTNET]: [...storyTestnet.rpcUrls.default.http],
  [ChainId.MORPH_TESTNET]: [...morphTestnet.rpcUrls.default.http],
  [ChainId.DUCK_TESTNET]: [...duckTestnet.rpcUrls.default.http],
  [ChainId.WORLDCHAIN]: [...worldChain.rpcUrls.default.http],
  // [ChainId.XLAYER_TESTNET]: [...xlayerTestnet.rpcUrls.default.http],
  // [ChainId.ZKFAIR]: [...zkfair.rpcUrls.default.http],
  // [ChainId.ZKFAIR_TESTNET]: [...zkfairTestnet.rpcUrls.default.http],
} satisfies Record<ChainId, readonly string[]>

// ADD CHAIN
export const PUBLIC_NODES = {
  [ChainId.ETHEREUM]: ['https://ethereum.publicnode.com', 'https://eth.llamarpc.com', 'https://cloudflare-eth.com'],
  [ChainId.ZETA]: [
    'https://zetachain-mainnet-archive.allthatnode.com:8545',
    'https://zetachain-mainnet-rpc.allthatnode.com:8545/1Z8elL21s0HDJiYPTduQvpSXNP7mxj3x',
    ...zeta.rpcUrls.default.http,
  ],
  [ChainId.ZETA_TESTNET]: [
    'https://zetachain-athens-evm.blockpi.network/v1/rpc/public',
    'https://zetachain-testnet-archive.allthatnode.com:8545/1Z8elL21s0HDJiYPTduQvpSXNP7mxj3x',
    ...zetaTestnet.rpcUrls.default.http,
  ],
  [ChainId.ZULU_TESTNET]: [...zuluTestnet.rpcUrls.default.http],
  [ChainId.SHAPE_TESTNET]: [...shapeTestnet.rpcUrls.default.http],
  [ChainId.SHAPE]: [...shape.rpcUrls.default.http],
  [ChainId.STORY_TESTNET]: [...storyTestnet.rpcUrls.default.http],
  [ChainId.MORPH_TESTNET]: [...morphTestnet.rpcUrls.default.http],
  [ChainId.DUCK_TESTNET]: [...duckTestnet.rpcUrls.default.http],
  [ChainId.WORLDCHAIN]: [...worldChain.rpcUrls.default.http],
  // [ChainId.BLAST]: [...blast.rpcUrls.default.http],
  // [ChainId.XLAYER]: [...xlayer.rpcUrls.default.http],

  // [ChainId.XLAYER_TESTNET]: [...xlayerTestnet.rpcUrls.default.http],
  // [ChainId.ZKFAIR]: [...zkfair.rpcUrls.default.http],
  // [ChainId.ZKFAIR_TESTNET]: [...zkfairTestnet.rpcUrls.default.http],
  // [ChainId.REDSTONE]: [...redstone.rpcUrls.default.http],
} satisfies Record<ChainId, readonly string[]>
