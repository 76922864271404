import { INIT_CODE_HASH } from '../common/swap'
import BaseChain from '../type'

export const worldChain: BaseChain = {
  id: 480,
  name: 'World Chain',
  features: ['swap'],
  network: 'WC',
  rpcUrls: {
    default: {
      http: ['https://worldchain-mainnet.g.alchemy.com/public'],
    },
  },
  blockExplorers: {
    default: {
      name: 'worldChainScan',
      url: 'https://worldchain-mainnet.explorer.alchemy.com',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 2961994,
    },
    multicall2: {
      address: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
      blockCreated: 3162183,
    },
  },
  testnet: false,
  wrappedNative: {
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ether',
  },
  platformAddress: '0x29d6eF6499253B5F39927200713c64105Fe9D589',
  //   stableToken: {
  //     address: '0x8ACeb2687B59F97Da274FE9135C3fAda0751ecb2',
  //     decimals: 18,
  //     symbol: 'USDT.ETH',
  //     name: 'ZetaChain ZRC20 USDT on ETH',
  //   },
  swap: {
    factoryAddress: '0x989E2F68902DeA76BCA7b54Fd65af749853DEc80',
    routerAddress: '0x52c193C8B5324c280adf0459d265Ff4885b22849',
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
