import { Token } from '@pancakeswap/sdk'
import { ChainId } from '@sdk/constants'
import { DEFAULT_TOKEN_LIST } from 'config/constants/tokenLists'
import memoize from 'lodash/memoize'
import { safeGetAddress } from 'utils'
import { Address, isAddress } from 'viem'

// ADD CHAIN
const mapping = {}

const mappingGithub = {
  [ChainId.ZETA]: 'zetachain',
  [ChainId.ZETA_TESTNET]: 'zetachain',
  [ChainId.ZULU_TESTNET]: 'zulu',
  [ChainId.SHAPE_TESTNET]: 'shapel2',
  [ChainId.STORY_TESTNET]: 'story',
}

const logoUriDefault = memoize(() =>
  DEFAULT_TOKEN_LIST.tokens.reduce((state, token) => {
    if (!state[token.chainId as ChainId]) {
      state[token.chainId as ChainId] = {}
    }

    state[token.chainId as ChainId][token.address as Address] = token.logoURI
    return state
  }, {} as Record<ChainId, Record<Address, string>>),
)

const getTokenLogoURL = memoize(
  (token?: Token) => {
    const chain = 'multex'
    if (!token || !isAddress(token.address)) return null

    const mapDefault = logoUriDefault()

    if (mapDefault?.[token.chainId]?.[token.address]) {
      return mapDefault?.[token.chainId]?.[token.address]
    }

    if (mappingGithub[token.chainId]) {
      // return `https://github.com/TunaWho/token-asset/blob/master/blockchains/${
      //   mapping[token.chainId]
      // }/assets/${safeGetAddress(token.address)}/logo.png`
      return `https://${chain}.s3.ap-southeast-1.amazonaws.com/${token.chainId}/${safeGetAddress(token.address)}.png`
    }

    if (mapping[token.chainId]) {
      // return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${safeGetAddress(
      //   token.address,
      // )}/logo.png`
      return `https://${chain}.s3.ap-southeast-1.amazonaws.com/${token.chainId}/${safeGetAddress(token.address)}.png`
    }
    return null
  },
  (t) => `${t?.chainId}#${t?.address}`,
)

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId] && isAddress(address)) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[chainId]}/assets/${safeGetAddress(
        address,
      )}/logo.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
