import { ChainId, getChain } from '@sdk/constants'
// import { BloctoConnector } from '@pancakeswap/wagmi/connectors/blocto'
// import { TrustWalletConnector } from '@pancakeswap/wagmi/connectors/trustWallet'
import { CHAINS } from 'config/chains'
import { PUBLIC_NODES } from 'config/nodes'
import { ConnectorNames } from 'config/wallet'
import memoize from 'lodash/memoize'
import { Chain, http } from 'viem'
import { createConfig, createStorage } from 'wagmi'
import { getPublicClient } from 'wagmi/actions'
import { coinbaseWallet, injected, metaMask, walletConnect } from 'wagmi/connectors'

export const publicClient = ({ chainId }: { chainId?: ChainId }) => getPublicClient(config, { chainId })

export const injectedConnector = injected({})

export const coinbaseConnector = coinbaseWallet({
  appName: 'Multex',
  appLogoUrl: '#',
})

export const walletConnectConnector = walletConnect({
  showQrModal: true,
  projectId: 'e542ff314e26ff34de2d4fba98db70bb',
})

export const walletConnectNoQrCodeConnector = walletConnect({
  showQrModal: false,
  projectId: 'e542ff314e26ff34de2d4fba98db70bb',
})

export const metaMaskConnector = metaMask({})

export const okxConnector = injected({
  target: {
    id: ConnectorNames.Okx,
    name: 'OKX Wallet',
    provider: (window) => {
      return (window as any)?.okxwallet
    },
  },
})

// const bloctoConnector = new BloctoConnector({
//   chains: config.chains,
//   options: {
//     defaultChainId: 56,
//     appId: 'e2f2f0cd-3ceb-4dec-b293-bb555f2ed5af',
//   },
// })

// const ledgerConnector = new LedgerConnector({
//   chains,
//   options: {
//     projectId: 'e542ff314e26ff34de2d4fba98db70bb',
//   },
// })

// export const bscConnector = new BinanceWalletConnector({ chains })

// export const trustWalletConnector = new TrustWalletConnector({
//   chains,
//   options: {
//     shimDisconnect: false,
//     shimChainChangedDisconnect: true,
//   },
// })

export const noopStorage = {
  getItem: (_key) => '',
  setItem: (_key, _value) => {},
  removeItem: (_key) => {},
}

export const config = createConfig({
  chains: CHAINS as unknown as [Chain, ...Chain[]],
  storage: createStorage({
    storage: typeof window !== 'undefined' && window.localStorage ? window.localStorage : noopStorage,
    key: 'multex',
  }),
  connectors: [
    metaMaskConnector,
    injectedConnector,
    walletConnectConnector,
    okxConnector,
    // coinbaseConnector,
    // bscConnector,
    // @ts-ignore FIXME: wagmi
    // bloctoConnector,
    // ledgerConnector,
    // trustWalletConnector,
  ],
  transports: CHAINS.reduce((state, i) => {
    const random = Math.floor(Math.random() * PUBLIC_NODES[i.id].length)
    if (PUBLIC_NODES?.[i.id]?.[random]) {
      state[i.id] = http(PUBLIC_NODES[i.id][random])
    } else {
      state[i.id] = http()
    }
    return state
  }, {}),
  batch: {
    multicall: {
      batchSize: 1024 * 200,
      wait: 16,
    },
  },
  pollingInterval: 6_000,
})

export const { chains } = config
export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => (CHAIN_IDS as number[]).includes(chainId))
export const isChainTestnet = memoize((chainId: number) => {
  const found = getChain(chainId)
  return found ? 'testnet' in found && found.testnet : false
})
export const getZetaChainByAnotherChain = (chainId?: ChainId) =>
  chainId && isChainTestnet(chainId) ? ChainId.ZETA_TESTNET : ChainId.ZETA
// export const getBTCChainByAnotherChain = (chainId: ChainId) =>
//   isChainTestnet(chainId) ? ChainId.BTC_TESTNET : ChainId.BTC
