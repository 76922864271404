import BaseChain from '../type'

export const bsc: BaseChain = {
  id: 56,
  name: 'Binance Smart Chain',
  features: ['swap', 'cross-swap'],
  network: 'BSC',
  rpcUrls: {
    default: {
      http: ['https://bsc-dataseed1.binance.org'],
    },
  },
  blockExplorers: {
    default: {
      name: 'BinanceScan',
      url: 'https://bscscan.com/',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Smart Chain',
    symbol: 'BNB',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 0,
    },
  },
  swap: {
    factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    initCodeHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
    routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  },
  testnet: false,
  wrappedNative: {
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    symbol: 'WBNB',
    name: 'Wrapped BNB',
  },
  stableToken: {
    address: '0x55d398326f99059fF775485246999027B3197955',
    decimals: 18,
    symbol: 'USDT',
    name: 'Tether USD',
  },
}
