import { FACTORY_ADDRESS, INIT_CODE_HASH, ROUTER_ADDRESS } from '../common/swap'
import BaseChain from '../type'

export const shape: BaseChain = {
  id: 360,
  name: 'Shape Mainnet',
  features: ['swap'],
  network: 'SHAPE',
  rpcUrls: {
    default: {
      http: ['https://mainnet.shape.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ShapeScan',
      url: 'https://shapescan.xyz',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0xAA45a6e4e1E6e43c14B366Dd0228874fb1DC0eF9',
      blockCreated: 2961994,
    },
    multicall2: {
      address: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
      blockCreated: 3162183,
    },
  },
  testnet: false,
  wrappedNative: {
    address: '0x48A9B22b80F566E88f0f1DcC90Ea15A8A3bAE8a4',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ether',
  },
  platformAddress: '0x874D98E8c6B172A4944554ECa42194ad09836919',
  //   stableToken: {
  //     address: '0x8ACeb2687B59F97Da274FE9135C3fAda0751ecb2',
  //     decimals: 18,
  //     symbol: 'USDT.ETH',
  //     name: 'ZetaChain ZRC20 USDT on ETH',
  //   },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    routerAddress: ROUTER_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
