import { ChainId, CurrencyAmount, Pair, WNATIVE, ERC20Token, pancakePairV2ABI } from '@pancakeswap/sdk'
import { MULTEX, USD } from '@pancakeswap/tokens'
import { BIG_ONE, BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { FAST_INTERVAL } from 'config/constants'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { publicClient } from 'utils/wagmi'

// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = ({ enabled = true } = {}) => {
  const { chainId } = useActiveChainId()
  const { data } = useQuery<BigNumber, Error>({
    queryKey: ['cakePrice'],
    queryFn: async () => new BigNumber(await getAbsPriceFromV2Pair(chainId)),
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
    enabled,
  })
  return data ?? BIG_ZERO
}

export const getCakePriceFromOracle = async () => {
  return undefined
  // const data = await publicClient({ chainId: ChainId.BSC }).readContract({
  //   abi: chainlinkOracleABI,
  //   address: contracts.chainlinkOracleCAKE[ChainId.BSC],
  //   functionName: 'latestAnswer',
  // })

  // return formatUnits(data, 8)
}

const getAbsPriceFromV2Pair = async (chainId: ChainId) => {
  const pricingChain = chainId
  const priceAbs = await getPriceFromLp(chainId, {
    address: Pair.getAddress(MULTEX[pricingChain], WNATIVE[pricingChain]),
    tokenA: MULTEX[pricingChain],
    tokenB: WNATIVE[pricingChain],
  })

  const priceNative = await getPriceFromLp(chainId, {
    address: Pair.getAddress(USD[pricingChain], WNATIVE[pricingChain]),
    tokenA: USD[pricingChain],
    tokenB: WNATIVE[pricingChain],
  })

  return new BigNumber(priceNative).times(priceAbs)
}

interface PairConfig {
  address: `0x${string}`
  tokenA: ERC20Token
  tokenB: ERC20Token
}

const getPriceFromLp = async (chainId: ChainId, pairConfig: PairConfig) => {
  const client = publicClient({ chainId })
  const [reserve0, reserve1] = await client.readContract({
    abi: pancakePairV2ABI,
    address: pairConfig.address,
    functionName: 'getReserves',
  })

  const { tokenA, tokenB } = pairConfig

  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

  const pair = new Pair(
    CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
    CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
  )

  return tokenA.symbol === USD[chainId].symbol && tokenB.symbol === WNATIVE[chainId].symbol
    ? BIG_ONE.div(pair.priceOf(tokenA).toSignificant(9))
    : pair.priceOf(tokenA).toSignificant(9)
}

// const getAbsPriceFromV2Pair = async (chainId: ChainId) => {
//   const pricingChain = chainId
//   const pairConfig = {
//     address: Pair.getAddress(MULTEX[pricingChain], USD[pricingChain]),
//     tokenA: MULTEX[pricingChain],
//     tokenB: USD[pricingChain],
//   }

//   const client = publicClient({ chainId: pricingChain })
//   const [reserve0, reserve1] = await client.readContract({
//     abi: pancakePairV2ABI,
//     address: pairConfig.address,
//     functionName: 'getReserves',
//   })

//   const { tokenA, tokenB } = pairConfig

//   const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

//   const pair = new Pair(
//     CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
//     CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
//   )

//   return pair.priceOf(tokenA).toSignificant(6)
// }
