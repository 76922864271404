import { useIsMounted } from "@pancakeswap/hooks";
import throttle from "lodash/throttle";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "styled-components";
import { AtomBox } from "../../components/AtomBox";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import Footer from "../../components/Footer";
import MenuItems from "../../components/MenuItems/MenuItems";
import MenuItemsMobile from "../../components/MenuItemsMobile";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { CONTAINER, MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { responsiveWrapper } from "./Menu.css";
import { NavProps } from "./types";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  background: url("/images/bg.svg") no-repeat;
  background-position: center;
  background-size: cover;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${CONTAINER}px;
  margin: auto;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${MENU_HEIGHT + 2}px;
  transform: translate3d(0, 0, 0);
  /* background-color: ${({ theme }) => theme.nav.background}; */

  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-left: 36px;
    padding-right: 36px;
  }
`;

const FixedContainer = styled("div").withConfig({
  shouldForwardProp: (props) => !["showMenu"].includes(props),
})<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
  /* background-color: ${({ theme }) => theme.nav.background}; */
  background-color: #000;
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  buyCakeLink,
  children,
  chainId,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  const subLinksWithoutMobile = useMemo(() => subLinks?.filter((subLink) => !subLink.isMobileOnly), [subLinks]);
  const subLinksMobileOnly = useMemo(() => subLinks?.filter((subLink) => subLink.isMobileOnly), [subLinks]);
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);

  return (
    <>
      <MenuContext.Provider value={providerValue}>
        <AtomBox
          asChild
          // minHeight={{
          //   xs: "100vh",
          //   md: "100vh",
          // }}
          className={responsiveWrapper}
        >
          <Wrapper>
            <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
              <Container>
                {banner && isMounted && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>}
                <StyledNav>
                  <Flex width="fit-content" maxWidth="100%" overflow="hidden">
                    <Flex alignItems="center" px="8px">
                      <Logo href={homeLink?.href ?? "/"} />
                    </Flex>

                    <AtomBox display={{ xs: "none", md: "block" }}>
                      <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" />
                    </AtomBox>
                  </Flex>
                  <Flex alignItems="center" height="100%">
                    {rightSide}
                  </Flex>
                </StyledNav>
              </Container>
            </FixedContainer>

            {subLinks ? (
              <Flex justifyContent="space-around" overflow="hidden">
                <SubMenuItems
                  items={subLinksWithoutMobile}
                  mt={`${totalTopMenuHeight + 1}px`}
                  activeItem={activeSubItem}
                />

                {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                  <SubMenuItems
                    items={subLinksMobileOnly}
                    mt={`${totalTopMenuHeight + 1}px`}
                    activeItem={activeSubItem}
                    isMobileOnly
                  />
                )}
              </Flex>
            ) : null}

            <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : "0"}>
              <Inner>{children}</Inner>
            </BodyWrapper>
          </Wrapper>
        </AtomBox>
        <Footer
          chainId={chainId}
          items={footerLinks}
          isDark={isDark}
          toggleTheme={toggleTheme}
          langs={langs}
          setLang={setLang}
          currentLang={currentLang}
          cakePriceUsd={cakePriceUsd}
          buyCakeLabel={buyCakeLabel}
          buyCakeLink={buyCakeLink}
          // mb={[`${MOBILE_MENU_HEIGHT}px`, null, null, "0px"]}
        />
        <AtomBox display={{ xs: "block", md: "none" }}>
          <MenuItemsMobile items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" />
        </AtomBox>
      </MenuContext.Provider>
    </>
  );
};

export default Menu;

// eslint-disable-next-line no-lone-blocks
{
  /*
              <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
                <CakePrice chainId={chainId} showSkeleton={false} cakePriceUsd={cakePriceUsd} />
              </AtomBox>

              <Box mt="4px">
                <LangSelector
                  currentLang={currentLang}
                  langs={langs}
                  setLang={setLang}
                  buttonScale="xs"
                  color="textSubtle"
                  hideLanguage
                />
              </Box>
  */
}
