import { styled } from "styled-components";
import { darkColors } from "../../theme/colors";
import { CONTAINER } from "../../widgets/Menu/config";
import { Box, Flex, Grid } from "../Box";
import SocialLinks from "./Components/SocialLinks";

export const Container = styled(Flex).attrs({ flexDirection: ["column", "column", "column", "row", "row"] })`
  width: 100%;
  max-width: ${CONTAINER}px;
  margin: auto;
  display: flex;
  gap: 16px;
`;

export const StyledFooter = styled(Grid)`
  background: var(--black-color);
  position: relative;
  width: 100%;
  margin-bottom: 70px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 25px;
    background: linear-gradient(180deg, #666666 -175%, #000000 100%);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0px;
  }
`;

export const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &:first-child {
    color: ${darkColors.secondary};
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const StyledToolsContainer = styled(Flex)`
  border-color: ${darkColors.cardBorder};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`;

export const StyledSocialLinks = styled(SocialLinks)``;

export const StyledText = styled.span`
  color: ${darkColors.text};
`;
