import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 8 7" {...props}>
      <path d="M2.60978 0V0.933333H5.69938L0.265625 6.342L0.926677 7L6.36044 1.59133V4.66667H7.2981V0H2.60978Z" />
    </Svg>
  );
};

export default Icon;
