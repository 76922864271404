import { FACTORY_ADDRESS, INIT_CODE_HASH, PLATFORM_TOKEN, ROUTER_ADDRESS } from '../common/swap'
import BaseChain from '../type'

export const zetaTestnet: BaseChain = {
  id: 7001,
  name: 'ZetaChain Athens',
  features: ['swap'],
  network: 'ZETATestnet',
  rpcUrls: {
    default: {
      http: ['https://zetachain-mainnet-rpc.allthatnode.com:8545/1Z8elL21s0HDJiYPTduQvpSXNP7mxj3x'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ZetaScan',
      url: 'https://athens.explorer.zetachain.com',
    },
  },
  testnet: true,
  nativeCurrency: {
    decimals: 18,
    name: 'Zeta',
    symbol: 'ZETA',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 0,
    },
    multicall2: {
      address: '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1',
      blockCreated: 1564229,
    },
  },
  wrappedNative: {
    address: '0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf',
    decimals: 18,
    symbol: 'WZETA',
    name: 'Wrapped ZETA',
  },
  platformAddress: PLATFORM_TOKEN,
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    routerAddress: ROUTER_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
