import { GraphQLClient } from 'graphql-request'
import { infoClient, infoClientETH, infoStableSwapClient } from 'utils/graphql'

import { ChainId, chains } from '@sdk/constants'
import { BLOCKS_CLIENT_WITH_CHAIN } from 'config/constants/endpoints'
import { PCS_ETH_START, PCS_V2_START } from 'config/constants/info'

export type MultiChainName = keyof typeof ChainId

export type MultiChainNameExtend = MultiChainName

// ADD CHAIN
export const multiChainName: Record<number | string, MultiChainNameExtend> = {
  [ChainId.ZETA]: 'ZETA',
  [ChainId.ZULU_TESTNET]: 'ZULU_TESTNET',
}
// ADD CHAIN
export const multiChainShortName: Record<number, string> = {}

// ADD CHAIN
export const multiChainQueryMainToken = chains.reduce(
  (acc, chain) => ({ ...acc, [chain.network.toUpperCase()]: 'ETH' }),
  {},
) as Record<MultiChainName, string>

// ADD CHAIN
export const multiChainBlocksClient = chains.reduce(
  (acc, chain) => ({ ...acc, [chain.network.toUpperCase()]: BLOCKS_CLIENT_WITH_CHAIN[chain.id] }),
  {},
) as Record<MultiChainName, string>

// ADD CHAIN
export const multiChainStartTime = {
  BSC: PCS_V2_START,
  ETH: PCS_ETH_START,
  ZETA: 0,
}

// ADD CHAIN
export const multiChainId = chains.reduce(
  (acc, chain) => ({ ...acc, [chain.network.toUpperCase()]: chain.id }),
  {},
) as Record<MultiChainName, ChainId>

// ADD CHAIN
export const multiChainPaths = chains.reduce((acc, chain) => ({ ...acc, [chain.id]: '' }), {}) as Record<
  ChainId,
  string
>

// ADD CHAIN
export const multiChainQueryClient = {
  BSC: infoClient,
  ETH: infoClientETH,
  ZETA: null,
}

// ADD CHAIN
export const multiChainScan = chains.reduce(
  (acc, chain) => ({
    ...acc,
    [chain.network.toUpperCase()]: `${chain.network.charAt(0).toUpperCase() + chain.network.slice(1)}Scan`,
  }),
  {},
) as Record<MultiChainName, string>

export const multiChainTokenBlackList = chains.reduce(
  (acc, chain) => ({ ...acc, [chain.network.toUpperCase()]: [''] }),
  {},
) as Record<MultiChainName, string>

export const multiChainTokenWhiteList = chains.reduce(
  (acc, chain) => ({ ...acc, [chain.network.toUpperCase()]: [''] }),
  {},
) as Record<MultiChainName, string>

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainNameExtend): GraphQLClient => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

// FIXME: this should be per chain
export const subgraphTokenName = {
  '0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C': 'Ankr Staked MATIC',
  '0x14016E85a25aeb13065688cAFB43044C2ef86784': 'True USD Old',
}

// FIXME: this should be per chain
export const subgraphTokenSymbol = {
  '0x14016E85a25aeb13065688cAFB43044C2ef86784': 'TUSDOLD',
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')

export const ChainLinkSupportChains = []
