import { keyframes, styled } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.colors.primary};
  }
`;

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export function CircleLoader({ size = "16px", stroke, ...rest }: { size?: string; stroke?: string; [k: string]: any }) {
  return (
    <StyledSVG viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
      <circle cx="11" cy="11" r="9" stroke="url(#paint0_linear_6165_4777)" stroke-width="4" />
      <defs>
        <linearGradient id="paint0_linear_6165_4777" x1="11" y1="0" x2="11" y2="22" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="0.44" stop-color="#666666" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </StyledSVG>
  );
}
