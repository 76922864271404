import BaseChain from '../type'

export const zuluTestnet: BaseChain = {
  id: 20240110,
  name: 'ZuluPrime Sipho Testnet',
  features: ['swap'],
  network: 'ZULU',
  rpcUrls: {
    default: {
      http: ['https://rpc-testnet.zulunetwork.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Zuluscan',
      url: 'https://testnet.zuluscan.io',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0x3cB5FA08F9b27Ea9867b97d966B6507B07c467D4',
      blockCreated: 3162643,
    },
    multicall2: {
      address: '0x05CE60159A9CD40EEAFe066e7cD81d167dbbd412',
      blockCreated: 3162183,
    },
  },
  testnet: true,
  wrappedNative: {
    address: '0xc6b8f3C67c0062aCA19BbC129f0293e830e09c91',
    decimals: 18,
    symbol: 'WBTC',
    name: 'Wrapped BTC',
  },
  platformAddress: '0x230Db970347264D7063A16C7f7dB3230BC47EaF1',
  swap: {
    factoryAddress: '0x4Ee94444336F0F35dfCa5980616eeaa59Bbea571',
    routerAddress: '0xb88a4312DCF8d883A5AbAc12C6E75c74a1929ed5',
    initCodeHash: '0x010005e1c9a788b42b0bb9be545b3beb716daf297e5cbc85119afb6a48abae2b',
  },
  smartRouterAddress: '0x',
}
