import { Colors, HelpIcon } from '@pancakeswap/uikit'
import { ChainId } from '@sdk/constants'
// import { ASSET_CDN } from 'config/constants/endpoints'
import Image from 'next/image'
import { memo } from 'react'
import { isChainSupported } from 'utils/wagmi'
import { localLogo } from './constants'

export const getChainLogo = (chainId: ChainId) => localLogo[chainId] // ?? `${ASSET_CDN}/web/chains/${chainId}.png`

export const ChainLogo = memo(
  ({
    chainId,
    width = 24,
    height = 24,
    color = 'white',
  }: {
    chainId: number
    width?: number
    height?: number
    color?: keyof Colors
  }) => {
    if (isChainSupported(chainId)) {
      const src = getChainLogo(chainId)
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px`, borderRadius: '50%' }}
          src={src}
          width={width}
          height={height}
          unoptimized
        />
      )
    }

    return <HelpIcon width={width} height={height} color={color} />
  },
)
