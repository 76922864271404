import BaseChain from '../type'

export const bitcoinTestnet: BaseChain = {
  id: 18332,
  name: 'Bitcoin Testnet',
  features: ['cross-swap'],
  network: 'bitcoin',
  rpcUrls: {
    public: {
      http: [''],
    },
    default: {
      http: [''],
    },
  },
  blockExplorers: {
    default: {
      name: 'Bitcoin Explorer',
      url: 'https://live.blockcypher.com/btc-testnet',
    },
  },
  testnet: true,
  nativeCurrency: {
    decimals: 8,
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  blockInterval: 1200,
  wrappedNative: {
    // this address zrcBTC
    address: '0x65a45c57636f9BcCeD4fe193A602008578BcA90b',
    decimals: 8,
    symbol: 'WBTC',
    name: 'Wrapped BTC',
  },
}
