import { FACTORY_ADDRESS, INIT_CODE_HASH, PLATFORM_TOKEN, ROUTER_ADDRESS } from '../common/swap'
import BaseChain from '../type'

export const shapeTestnet: BaseChain = {
  id: 11011,
  name: 'Shape Sepolia Testnet',
  features: ['swap'],
  network: 'SHAPE_TESTNET',
  rpcUrls: {
    default: {
      http: ['https://sepolia.shape.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ShapeScan',
      url: 'https://explorer-sepolia.shape.network',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'ETH on Shape',
    symbol: 'ETH',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
      blockCreated: 3162643,
    },
    multicall2: {
      address: '0x874D98E8c6B172A4944554ECa42194ad09836919',
      blockCreated: 3162183,
    },
  },
  testnet: true,
  wrappedNative: {
    address: '0x48A9B22b80F566E88f0f1DcC90Ea15A8A3bAE8a4',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ether',
  },
  platformAddress: PLATFORM_TOKEN,
  //   stableToken: {
  //     address: '0x8ACeb2687B59F97Da274FE9135C3fAda0751ecb2',
  //     decimals: 18,
  //     symbol: 'USDT.ETH',
  //     name: 'ZetaChain ZRC20 USDT on ETH',
  //   },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    routerAddress: ROUTER_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
