import { ERC20Token } from '@pancakeswap/sdk'
import type { FarmV3SupportedChainId } from '../../src'
import type { CommonPrice } from '../../src/fetchFarmsV3'

export type PriceHelper = {
  chain: string
  list: ERC20Token[]
}

export const priceHelperTokens: Record<number, PriceHelper> = {}

// for testing purposes
export const DEFAULT_COMMON_PRICE: Record<FarmV3SupportedChainId, CommonPrice> = {}
