import { masterChefV3Addresses } from '@pancakeswap/farms'
import { ChainId, chains } from '@sdk/constants'
// import { GAUGES_ADDRESS, GAUGES_CALC_ADDRESS } from '@pancakeswap/gauges'
// import { ICAKE } from '@pancakeswap/ifos'
import { CAKE_VAULT } from '@pancakeswap/pools'
import { V3_QUOTER_ADDRESSES } from '@pancakeswap/smart-router/evm'
import { DEPLOYER_ADDRESSES } from '@pancakeswap/v3-sdk'
import { Address } from 'viem'

export default {
  masterChef: {},
  masterChefV3: masterChefV3Addresses,
  masterChefV1: {},
  sousChef: {},
  lotteryV2: {},
  multiCall: chains.reduce((acc, chain) => {
    if (!chain.contracts || !chain.contracts.multicall3) return acc
    return { ...acc, [chain.id]: chain.contracts.multicall3.address }
  }, {}) as { [key in ChainId]: Address },
  pancakeProfile: {},
  pancakeBunnies: {},
  bunnyFactory: {},
  claimRefund: {},
  pointCenterIfo: {},
  bunnySpecial: {},
  tradingCompetitionEaster: {},
  tradingCompetitionFanToken: {},
  tradingCompetitionMobox: {},
  tradingCompetitionMoD: {},
  easterNft: {},
  cakeVault: CAKE_VAULT,
  cakeFlexibleSideVault: {},
  predictionsBNB: {},
  predictionsCAKE: {},
  chainlinkOracleBNB: {},
  chainlinkOracleCAKE: {},
  predictionsV1: {},
  bunnySpecialCakeVault: {},
  bunnySpecialPrediction: {},
  bunnySpecialLottery: {},
  bunnySpecialXmas: {},
  farmAuction: {},
  nftMarket: {},
  nftSale: {},
  pancakeSquad: {},
  potteryDraw: {},
  zap: {},
  stableSwapNativeHelper: {},
  // iCake: ICAKE,
  bCakeFarmBooster: {},
  bCakeFarmBoosterProxyFactory: {},
  bCakeFarmBoosterV3: {},
  nonBscVault: {},
  crossFarmingSender: {},
  crossFarmingReceiver: {},
  mmLinkedPool: {},
  tradingReward: {},
  nftPositionManager: {},
  v3PoolDeployer: DEPLOYER_ADDRESSES,
  v3Migrator: {},
  quoter: V3_QUOTER_ADDRESSES,
  v3Airdrop: {},
  affiliateProgram: {},
  tradingRewardTopTrades: {},
  vCake: {},
  revenueSharingPool: {},
  anniversaryAchievement: {},
  fixedStaking: {},
  veCake: {},
  // gaugesVoting: GAUGES_ADDRESS,
  // gaugesVotingCalc: GAUGES_CALC_ADDRESS,
  revenueSharingVeCake: {},
  revenueSharingCakePool: {},
  revenueSharingPoolGateway: {},
  erc20Custody: {},
  omnichain: {
    [ChainId.ZETA]: '0x357cded48A21A83CD476bB216605af636f4623a5',
    [ChainId.ZETA_TESTNET]: '0x7FC7c27eb74Ca26F9843baE3864846cAbB2e43d4',
  },
  tss: {} as any,
} as const satisfies Record<string, Record<number, Address>>
