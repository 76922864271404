import { vars } from "../../css/vars.css";
import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.LG]: {
    height: "52px",
    padding: "0 24px",
  },
  [scales.MD]: {
    height: "44px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: "primary",
    color: "black",
  },
  [variants.SECONDARY]: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: "primary",
    boxShadow: "none",
    color: "primary",
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "primary",
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "backgroundAlt",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",
  },
  [variants.LIGHT]: {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none",
  },
  [variants.BUBBLEGUM]: {
    background: vars.colors.gradientBubblegum,
    color: "textSubtle",
    boxShadow: "none",
    ":disabled": {
      background: vars.colors.disabled,
    },
  },
  [variants.TAB_BUTTON]: {
    backgroundColor: "primary",
    color: "white",
    borderRadius: "inherit",
  },
  [variants.CONNECT_WALLET]: {
    background: "linear-gradient(0deg, #976e1c 0%, #e0a42a 100%)",
    color: "white",
    borderRadius: "30px",
  },
  [variants.SLIPPAGE_BUTTON_ACTIVE]: {
    backgroundColor: "primary",
    color: "white",
    border: "1px solid",
    borderColor: "var(--line)",
    borderRadius: "25px",
    boxShadow: "none",
  },
  [variants.SLIPPAGE_BUTTON_INACTIVE]: {
    backgroundColor: "tertiary",
    color: "white",
    border: "1px solid",
    borderColor: "var(--line)",
    borderRadius: "25px",
    boxShadow: "none",
  },
  [variants.CANCEL]: {
    backgroundColor: "#96A0A5",
    color: "white",
  },
};
