import { AtomBox, Text } from "@pancakeswap/uikit";
import { ReactNode, memo } from "react";

interface Props {
  title: ReactNode;
  subtitle: ReactNode;
}

export const CurrencyInputHeader = memo(({ title, subtitle }: Props) => {
  return (
    <AtomBox width="100%" alignItems="center" flexDirection="column" px="24px" pt="16px" pb="12px">
      <AtomBox display="flex" width="100%" alignItems="center" justifyContent="space-between">
        {title}
      </AtomBox>
      {subtitle}
    </AtomBox>
  );
});

export const CurrencyInputHeaderTitle = memo(({ children }: { children: ReactNode }) => (
  <Text
    style={{
      whiteSpace: "nowrap",
    }}
    fontSize={["clamp(20px, 4vw, 28px)"]}
    fontWeight={500}
    as="h2"
  >
    {children}
  </Text>
));

export const CurrencyInputHeaderSubTitle = memo(({ children }: { children: ReactNode }) => (
  <Text color="#000" fontWeight="500" fontSize={["clamp(14px, 2vw, 18px)"]} textAlign="center">
    {children}
  </Text>
));
