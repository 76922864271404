import React from "react";
import { Flex } from "../Box";
import { Container, StyledFooter, StyledSocialLinks } from "./styles";

import { Column, LogoWithTextIcon, Text } from "..";
import { FooterProps } from "./types";

const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  return (
    <StyledFooter
      data-theme="dark"
      p={["16px", null, "40px", "40px"]}
      flexDirection={["column", "column", "column", "column", "row"]}
      position="relative"
      {...props}
      gridTemplateColumns={["1fr"]}
      justifyContent={["center"]}
      gridGap={40}
    >
      <Container>
        <Flex alignItems="center" width="max-content" position="relative">
          <Column ml={["0px", "0px", "0px", "0px", "12px"]}>
            <Flex alignItems="center" mb="16px">
              <LogoWithTextIcon width="136px" mr="8px" />
            </Flex>
            <Text color="text">
              A Comprehensive DeFi Suite Built <br /> on Multi-EVM Blockchain
            </Text>
          </Column>
        </Flex>

        <Flex mr={["0px", "", "auto", "", "0"]} ml={["0px", "", "0", "", "auto"]} alignItems="center">
          <Text fontSize="16px" mr="12px">
            © 2024 Multex |{" "}
          </Text>
          <StyledSocialLinks mx={["0px", "", "auto"]} />
        </Flex>
      </Container>

      {/* <Flex mr={["auto", "", "auto", "", "0"]} ml={["auto", "", "0", "", "auto"]}>
        {infoLinks.map((link) => (
          <Link
            data-theme="dark"
            mx="16px"
            href={link.href}
            target="_blank"
            rel="noreferrer noopener"
            color="white"
            bold={false}
            external
          >
            {link.label}
          </Link>
        ))}
      </Flex> */}
    </StyledFooter>
  );
};

export default Footer;
