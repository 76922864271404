import { ChainId } from '@sdk/constants'
// import FarmsXLayerPriceHelper from './196'
import FarmsGoerliPriceHelper from './5'
import FarmsZetaPriceHelper from './7000'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    // case ChainId.XLAYER:
    //   return FarmsXLayerPriceHelper
    case ChainId.ZETA:
      return FarmsZetaPriceHelper
    case ChainId.GOERLI:
      return FarmsGoerliPriceHelper
    default:
      return []
  }
}
