import { MULTEX } from '@pancakeswap/tokens'
import { Flex, Skeleton, Text } from '@pancakeswap/uikit'
import { formatBigInt, getFullDisplayBalance } from '@pancakeswap/utils/formatBalance'
import {
  // ChainId,
  chains,
  DEFAULT_CHAINID,
} from '@sdk/constants'
// import { CurrencyLogo } from '@pancakeswap/widgets-internal'
import { CurrencyLogo } from 'components/Logo'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance from 'hooks/useTokenBalance'
import { useBalance } from 'wagmi'

const TokenTab = () => {
  const { account, chainId } = useActiveWeb3React()
  const filteredItems = chains.filter((item) => item.id === chainId)
  const { balance: absBalance, isFetched: absFetched } = useTokenBalance(filteredItems[0]?.platformAddress)
  const isDefaultChainId = chainId === DEFAULT_CHAINID

  const defaulToken = MULTEX[chainId]

  // const wZuluToken = WNATIVE[ChainId.ZULU_TESTNET]
  // const wZuluBalance = useBalance({
  //   address: account,
  //   chainId: ChainId.ZULU_TESTNET,
  // })

  // const wShapeToken = WNATIVE[ChainId.SHAPE_TESTNET]
  // const wShapeBalance = useBalance({
  //   address: account,
  //   chainId: ChainId.SHAPE_TESTNET,
  // })

  const nativeBalance = useBalance({
    address: account,
    query: {
      enabled: !isDefaultChainId,
    },
  })

  const native = useNativeCurrency()

  return (
    <>
      <Flex flexDirection="column">
        <TokenItem
          token={defaulToken}
          tokenBalance={getFullDisplayBalance(absBalance, defaulToken.decimals, 6)}
          isFetched={absFetched}
        />
        <TokenItem token={native} tokenBalance={formatBigInt(nativeBalance?.data?.value ?? 0n, 6)} isFetched />
        {/* <TokenItem token={wShapeToken} tokenBalance={formatBigInt(wShapeBalance?.data?.value ?? 0n, 6)} isFetched /> */}
      </Flex>
    </>
  )
}

export default TokenTab

const TokenItem = ({ token, tokenBalance, isFetched }) => {
  return (
    <>
      {isFetched ? (
        <Flex justifyContent="space-between" alignItems="center" padding="6px 0">
          <Flex style={{ gap: '6px' }}>
            <CurrencyLogo currency={token} size="31px" />
            <Flex flexDirection="column">
              <Text lineHeight="1">{token?.symbol}</Text>
              <Text lineHeight="1">{token?.name}</Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Text lineHeight="1" textAlign="right">
              {tokenBalance}
            </Text>
            {/* <Text lineHeight="1" textAlign="right">
              $0
            </Text> */}
          </Flex>
        </Flex>
      ) : (
        <Flex justifyContent="space-between" padding="6px 0">
          <Skeleton height="22px" width="60px" />
          <Skeleton height="22px" width="60px" />
        </Flex>
      )}
    </>
  )
}
