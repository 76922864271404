import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <rect width="32" height="32" rx="16" fill="#CA0000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3636 9.63569C22.6565 9.92858 22.6565 10.4035 22.3636 10.6963L17.0603 15.9996L22.3636 21.3029C22.6565 21.5958 22.6565 22.0707 22.3636 22.3636C22.0707 22.6565 21.5958 22.6565 21.3029 22.3636L15.9996 17.0603L10.6963 22.3636C10.4035 22.6565 9.92858 22.6565 9.63569 22.3636C9.34279 22.0707 9.34279 21.5958 9.63569 21.3029L14.939 15.9996L9.63569 10.6963C9.34279 10.4035 9.34279 9.92858 9.63569 9.63569C9.92858 9.34279 10.4035 9.34279 10.6963 9.63569L15.9996 14.939L21.3029 9.63569C21.5958 9.34279 22.0707 9.34279 22.3636 9.63569Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
