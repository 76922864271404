import { bsc, bscTestnet, shapeTestnet, zetaTestnet } from '@sdk/constants'
import { linea } from 'viem/chains'

export const chainNameConverter = (name: string) => {
  switch (name) {
    case bsc.name:
      return 'BNB Chain'
    case linea.name:
      return 'Linea'
    case zetaTestnet.name:
      return 'ZetaChain Athens'
    case bscTestnet.name:
      return 'BNB Chain Testnet'
    case shapeTestnet.name:
      return 'ShapeL2 Testnet'
    default:
      return name
  }
}
