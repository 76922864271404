import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 26 22" {...props}>
      <path
        d="M9.97916 13.8709L9.55946 19.7469C10.1599 19.7469 10.42 19.4902 10.7319 19.1819L13.5472 16.5037L19.3807 20.7561C20.4506 21.3496 21.2044 21.0371 21.493 19.7764L25.3221 1.91668L25.3232 1.91562C25.6625 0.341374 24.7512 -0.274225 23.7089 0.111971L1.20133 8.68932C-0.334761 9.28282 -0.311503 10.1352 0.940207 10.5214L6.69448 12.3029L20.0605 3.97814C20.6895 3.56353 21.2615 3.79294 20.791 4.20755L9.97916 13.8709Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
