import { ChainId } from './chains'
import { chainNames, defiLlamaChainNames } from './common/chainNames'

export function getChainName(chainId: ChainId) {
  return chainNames[chainId]
}

export function getLlamaChainName(chainId: ChainId) {
  return defiLlamaChainNames[chainId]
}

export function isChainZeta(chainId: ChainId): boolean {
  return [ChainId.ZETA, ChainId.ZETA_TESTNET].includes(chainId)
}
