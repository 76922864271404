import { useTranslation } from "@pancakeswap/localization";
import { AutoColumn, Box, CheckmarkCircleIcon, Text } from "@pancakeswap/uikit";
import { PropsWithChildren } from "react";

export const SwapTransactionReceiptModalContent: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <Box margin="auto auto auto auto" width="fit-content">
        <CheckmarkCircleIcon color="primary" width={100} height={100} />
      </Box>
      <AutoColumn gap="12px" justify="center">
        <Text bold textAlign="center">
          {t("Successful Transaction!")}
        </Text>
        {children}
      </AutoColumn>
    </Box>
  );
};
