import { CopyButton, Flex, FlexGap, LogoutIcon, OpenNewIcon, Text, UserMenuItem } from '@pancakeswap/uikit'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { ChainLogo } from 'components/Logo/ChainLogo'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBlockExploreLink } from 'utils'
// import { getZetaChainByAnotherChain } from 'utils/wagmi'

const UserAccount = ({ onClick, ...props }) => {
  const { account, chainId } = useActiveWeb3React()

  // const zetaChainId = getZetaChainByAnotherChain(chainId)

  return (
    account && (
      <>
        <Flex flexDirection="column" {...props}>
          <FlexGap>
            <UserMenuItem as="a" target="_" href={getBlockExploreLink(account, 'address', chainId)}>
              <Flex>
                <ChainLogo chainId={chainId} />
                <Text color="text80" small letterSpacing={1} ml="8px">
                  {account && truncateHash(account, 4, 6)}
                </Text>
              </Flex>
            </UserMenuItem>

            <FlexGap gap="6px">
              <CopyButton width="20px" color="text" tooltipMessage="Copied" text={account} />
              <OpenNewIcon width="22px" />
              <LogoutIcon width="18px" onClick={onClick} />
            </FlexGap>
          </FlexGap>
        </Flex>
      </>
    )
  )
}

export default UserAccount
