import { useTranslation } from '@pancakeswap/localization'
import { Box, Button, Flex, Text } from '@pancakeswap/uikit'
import isEmpty from 'lodash/isEmpty'
import { useAppDispatch } from 'state'
import { TransactionType, clearAllTransactions } from 'state/transactions/actions'
import { useAllSortedRecentTransactions } from 'state/transactions/hooks'
import { styled } from 'styled-components'
import { chains } from '../../../utils/wagmi'
import TransactionRow from './TransactionRow'

const TransactionsContainer = styled(Box)`
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: unset;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: unset;
  }
`

interface WalletTransactionsProps {
  onDismiss?: () => void
}

const WalletTransactions: React.FC<React.PropsWithChildren<WalletTransactionsProps>> = ({ onDismiss }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const sortedTransactions = useAllSortedRecentTransactions()

  const hasTransactions = !isEmpty(sortedTransactions)

  const handleClearAll = () => {
    dispatch(clearAllTransactions())
  }

  return (
    <Box minHeight="90px">
      <Flex alignItems="center" justifyContent="space-between" mb="16px">
        <Text fontWeight={500} fontSize="14px">
          {t('Recent Activities')}
        </Text>
        <Box minHeight="32px">
          {hasTransactions && (
            <Button scale="sm" onClick={handleClearAll} variant="text" px="0">
              <Text fontWeight={500} fontSize="14px">
                {t('Clear all')}
              </Text>
            </Button>
          )}
        </Box>
      </Flex>
      {hasTransactions ? (
        <TransactionsContainer>
          {Object.entries(sortedTransactions).map(([chainId, transactions]) => {
            const chainIdNumber = Number(chainId)
            return (
              <Box key={chainId}>
                <Text fontSize="14px" mb="12px" color="primary">
                  {chains.find((c) => c.id === chainIdNumber)?.name ?? 'Unknown network'}
                </Text>
                {Object.values(transactions).map((txn) => (
                  <TransactionRow
                    key={txn.hash}
                    txn={txn}
                    chainId={chainIdNumber}
                    type={txn.type as TransactionType}
                    onDismiss={onDismiss}
                  />
                ))}
              </Box>
            )
          })}
        </TransactionsContainer>
      ) : (
        <Text textAlign="center">{t('No recent activities')}</Text>
      )}
    </Box>
  )
}

export default WalletTransactions
