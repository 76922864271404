import BaseChain from '../type'

export const bitcoin: BaseChain = {
  id: 8332,
  name: 'Bitcoin Mainnet',
  features: ['cross-swap'],
  network: 'bitcoin',
  rpcUrls: {
    public: {
      http: [''],
    },
    default: {
      http: [''],
    },
  },
  blockExplorers: {
    default: {
      name: 'Bitcoin Explorer',
      url: 'https://live.blockcypher.com/btc',
    },
  },
  nativeCurrency: {
    decimals: 8,
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  blockInterval: 600,
  wrappedNative: {
    // this address zrcBTC
    address: '0x13A0c5930C028511Dc02665E7285134B6d11A5f4',
    decimals: 8,
    symbol: 'WBTC',
    name: 'Wrapped BTC',
  },
}
