// import { SUPPORTED_CHAIN_IDS as IFO_SUPPORTED_CHAINS } from '@pancakeswap/ifos'
import { ContextApi } from '@pancakeswap/localization'
import {
  BridgeIcon,
  DropdownMenuItems,
  DropdownMenuItemType,
  EarnFillIcon,
  EarnIcon,
  LiquidityIcon,
  MenuItemsType,
  SwapFillIcon,
  SwapIcon,
} from '@pancakeswap/uikit'
import { SUPPORT_FARMS } from 'config/constants/supportChains'
// import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean
  items?: ConfigMenuDropDownItemsType[]
  typeHref?: DropdownMenuItemType
}
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) => [
  {
    label: t('Trade'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/swap',
    showItemsOnMobile: false,
  },
  {
    label: t('Liquidity'),
    icon: LiquidityIcon,
    fillIcon: LiquidityIcon,
    href: '/liquidity',
    showItemsOnMobile: false,
  },
  {
    label: t('Pool'),
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    href: '#',
    showItemsOnMobile: false,
    supportChainIds: SUPPORT_FARMS,
  },
  {
    label: t('Bridge'),
    icon: BridgeIcon,
    fillIcon: BridgeIcon,
    href: '#',
    showItemsOnMobile: false,
    typeHref: DropdownMenuItemType.EXTERNAL_LINK,
    items: [
      {
        label: t('Shape'),
        href: 'https://superbridge.app/shape-mainnet',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Zulu Network'),
        href: 'https://testnet.zulunetwork.io/bridge/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Morph'),
        href: 'https://bridge-holesky.morphl2.io/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Duckchain'),
        href: 'https://test.orbiter.finance/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
  // {
  //   label: t('Earn'),
  //   href: '/farms',
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   image: '/images/decorations/pe2.png',
  //   showItemsOnMobile: false,
  //   supportChainIds: [...SUPPORT_FARMS, ...SUPPORT_STAKING],
  //   items: [
  //     {
  //       label: t('Liquidity Farms'),
  //       href: '/farms',
  //       supportChainIds: SUPPORT_FARMS,
  //     },
  //     {
  //       label: t('Staking'),
  //       href: '/pools',
  //       supportChainIds: SUPPORT_STAKING,
  //     },
  //   ].map((item) => addMenuItemSupported(item, chainId)),
  // },
  // {
  //   label: t('Cross Swap'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/cross-swap',
  //   showItemsOnMobile: false,
  // },
  // {
  //   label: t('Cross-Stake'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/cross-stake',
  //   showItemsOnMobile: false,
  //   disabled: true,
  //   isComingSoon: true,
  // },
  // {
  //   label: t('NFT MarkePlace'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/nft-marketplace',
  //   showItemsOnMobile: false,
  //   disabled: true,
  //   isComingSoon: true,
  // },
  // {
  //   label: t('Cross-Lending'),
  //   icon: SwapIcon,
  //   fillIcon: SwapFillIcon,
  //   href: '/cross-lending',
  //   showItemsOnMobile: false,
  //   disabled: true,
  //   isComingSoon: true,
  // },
]

export default config
