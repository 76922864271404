import { FACTORY_ADDRESS, INIT_CODE_HASH, PLATFORM_TOKEN, ROUTER_ADDRESS } from '../common/swap'
import BaseChain from '../type'

export const storyTestnet: BaseChain = {
  id: 1513,
  name: 'Story Public Testnet',
  features: ['swap'],
  network: 'STORY',
  rpcUrls: {
    default: {
      http: ['https://testnet.storyrpc.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'StoryScan',
      url: 'https://testnet.storyscan.xyz',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'IP',
    symbol: 'IP',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0x874D98E8c6B172A4944554ECa42194ad09836919',
      blockCreated: 3162643,
    },
    multicall2: {
      address: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
      blockCreated: 3162183,
    },
  },
  testnet: true,
  wrappedNative: {
    address: '0x43460a31A70708379d19911636285E4E76745aFF',
    decimals: 18,
    symbol: 'WIP',
    name: 'Wrapped IP',
  },
  platformAddress: PLATFORM_TOKEN,
  stableToken: {
    address: '0x8812d810EA7CC4e1c3FB45cef19D6a7ECBf2D85D',
    decimals: 18,
    symbol: 'SUSDT',
    name: 'SUSDT',
  },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    routerAddress: ROUTER_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
