import { ContextApi } from '@pancakeswap/localization'
import memoize from 'lodash/memoize'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Multex - A Comprehensive DeFi Suite Built on Multi-EVM Blockchain',
  description: '',
  // image: `${ASSET_CDN}/web/og/hero.jpg`,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/swap': {
        basePath: true,
        title: t('Exchange'),
        // image: `${ASSET_CDN}/web/og/swap.jpg`
      },
      '/limit-orders': {
        basePath: true,
        title: t('Limit Orders'),
        // image: `${ASSET_CDN}/web/og/limit.jpg`
      },
      '/add': {
        basePath: true,
        title: t('Add Liquidity'),
        // image: `${ASSET_CDN}/web/og/liquidity.jpg`
      },
      '/remove': {
        basePath: true,
        title: t('Remove Liquidity'),
        // image: `${ASSET_CDN}/web/og/liquidity.jpg`
      },
      '/liquidity': {
        title: t('Liquidity'),
        // image: `${ASSET_CDN}/web/og/liquidity.jpg`
      },
      '/find': { title: t('Import Pool') },
      '/competition': { title: t('Trading Battle') },
      '/farms': {
        title: t('Farms'),
        // image: `${ASSET_CDN}/web/og/farms.jpg`
      },
      '/farms/auction': {
        title: t('Farm Auctions'),
        // image: `${ASSET_CDN}/web/og/liquidity.jpg`
      },
      '/pools': {
        title: t('Pools'),
        // image: `${ASSET_CDN}/web/og/pools.jpg`
      },
      // '/ifo': { title: t('Initial Farm Offering'), image: `${ASSET_CDN}/web/og/ifo.jpg` },
      // '/teams': { basePath: true, title: t('Leaderboard'), image: `${ASSET_CDN}/web/og/teams.jpg` },
      '/info': {
        title: `${t('Overview')} - ${t('Info')}`,
        // description: 'View statistics for Pancakeswap exchanges.',
        // image: `${ASSET_CDN}/web/og/info.jpg`,
      },
      '/info/pairs': {
        title: `${t('Pairs')} - ${t('Info')}`,
        // description: 'View statistics for Pancakeswap exchanges.',
        // image: `${ASSET_CDN}/web/og/info.jpg`,
      },
      '/info/tokens': {
        title: `${t('Tokens')} - ${t('Info')}`,
        // description: 'View statistics for Pancakeswap exchanges.',
        // image: `${ASSET_CDN}/web/og/info.jpg`,
      },
      // '/nfts': { title: t('NFT Marketplace'), image: `${ASSET_CDN}/web/og/nft.jpg` },
      // '/nfts/collections': { basePath: true, title: t('Collections'), image: `${ASSET_CDN}/web/og/nft.jpg` },
      // '/nfts/activity': { title: t('Activity'), image: `${ASSET_CDN}/web/og/nft.jpg` },
      // '/profile': { basePath: true, title: t('Profile') },
    },
    defaultTitleSuffix: t('PancakeSwap'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
