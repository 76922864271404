import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 21 21" {...props}>
      <path
        d="M0.0511023 0.640625L8.14349 11.5422L0 20.4057H1.83277L8.96238 12.6456L14.7229 20.4057H20.9599L12.4122 8.89089L19.9921 0.640625H18.1593L11.5933 7.78752L6.28809 0.640625H0.0511023ZM2.74633 2.00079H5.61162L18.2642 19.0453H15.399L2.74633 2.00079Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
