import { ChainId } from '@sdk/constants'
import { Address } from 'viem'

export const WBETH: Record<number, Address> = {
  [ChainId.BSC]: '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
  [ChainId.BSC_TESTNET]: '0x34f8f72e3f14Ede08bbdA1A19a90B35a80f3E789',
}

export const SNBNB: Record<number, Address> = {
  [ChainId.BSC]: '0x1adB950d8bB3dA4bE104211D5AB038628e477fE6',
}
