import { ChainId, chains } from '@sdk/constants'
import uniq from 'lodash/uniq'

export const supportedChainIdV2 = chains.filter((chain) => chain.features.includes('farms')).map((chain) => chain.id)
export const supportedChainIdV3 = chains.filter((chain) => chain.features.includes('farmsV3')).map((chain) => chain.id)
export const supportedChainId = uniq([...supportedChainIdV2, ...supportedChainIdV3])
export const bCakeSupportedChainId: ChainId[] = []

export const FARM_AUCTION_HOSTING_IN_SECONDS = 691200

export type FarmSupportedChainId = (typeof supportedChainId)[number]

export type FarmV2SupportedChainId = (typeof supportedChainIdV2)[number]

export type FarmV3SupportedChainId = (typeof supportedChainIdV3)[number]

export const masterChefAddresses = {} as const

export const masterChefV3Addresses = {
  [ChainId.GOERLI]: '0xc378c540A8CD4e2F7475a1850d9E854C1Ea8b9E8',
} as const satisfies Record<FarmV3SupportedChainId, string>

export const nonBSCVaultAddresses = {} as const
