import { INIT_CODE_HASH } from '../common/swap'
import BaseChain from '../type'

export const morphTestnet: BaseChain = {
  id: 2810,
  name: 'Morph Holesky Testnet',
  features: ['swap'],
  network: 'Morphl2',
  rpcUrls: {
    default: {
      http: ['https://rpc-holesky.morphl2.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'MorphScan',
      url: 'https://explorer-holesky.morphl2.io',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'ETH on Morph',
    symbol: 'ETH',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0x874D98E8c6B172A4944554ECa42194ad09836919',
      blockCreated: 3162643,
    },
    multicall2: {
      address: '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1',
      blockCreated: 3162183,
    },
  },
  testnet: true,
  wrappedNative: {
    address: '0x5300000000000000000000000000000000000011',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ether',
  },
  platformAddress: '0x29d6eF6499253B5F39927200713c64105Fe9D589',
  //   stableToken: {
  //     address: '0x8ACeb2687B59F97Da274FE9135C3fAda0751ecb2',
  //     decimals: 18,
  //     symbol: 'USDT.ETH',
  //     name: 'ZetaChain ZRC20 USDT on ETH',
  //   },
  swap: {
    factoryAddress: '0x92E8FEa724ba52f098696D4d6D50A5890582Deeb',
    routerAddress: '0xA0768Ce7C4Ee48C291141992198F3292e2C3ed07',
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
