import { ChainId, chainsSetup } from '@sdk/constants'
import invert from 'lodash/invert'
import memoize from 'lodash/memoize'

export const CHAIN_QUERY_NAME = chainsSetup.reduce((acc, chain) => {
  const queryNames = acc
  queryNames[chain.id] = chain.network
  return queryNames
}, {} as Record<number, string>)

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = []

export const CHAINS = chainsSetup
