import { ChainId } from '@sdk/constants'

export const BAD_SRCS: { [imageSrc: string]: true } = {}

export const localLogo = {
  [ChainId.ZETA]: '/images/chains/7000.png',
  [ChainId.ZETA_TESTNET]: '/images/chains/7000.png',
  [ChainId.ZULU_TESTNET]: '/images/chains/20240110.png',
  [ChainId.SHAPE]: '/images/chains/11011.png',
  [ChainId.SHAPE_TESTNET]: '/images/chains/11011.png',
  [ChainId.STORY_TESTNET]: '/images/chains/1513.jpg',
  [ChainId.MORPH_TESTNET]: '/images/chains/2810.png',
  [ChainId.DUCK_TESTNET]: '/images/chains/202105.jpg',
  [ChainId.WORLDCHAIN]: '/images/chains/480.jpg',
}
