import { Flex, Text } from '@pancakeswap/uikit'
import { chains } from '@sdk/constants'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { chainNameConverter } from 'utils/chainNameConverter'

const DisplayChain = () => {
  const { chainId } = useActiveChainId()
  const filteredItems = chains.filter((item) => item.id === chainId)

  return (
    <>
      <Flex alignItems="center" mr="16px">
        <ChainLogo chainId={chainId} />
        <Text whiteSpace="nowrap" pl="12px" small display={['none', 'none', 'none', 'block']}>
          {chainNameConverter(filteredItems[0].name)}
        </Text>
      </Flex>
    </>
  )
}

export default DisplayChain
