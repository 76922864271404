import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Multex - A Comprehensive DeFi Suite Built on Multi-EVM Blockchain',
  defaultTitle: 'Multex - A Comprehensive DeFi Suite Built on Multi-EVM Blockchain',
  description: '',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Multex',
    site: '@Multex',
  },
  openGraph: {
    title: 'Multex - A Comprehensive DeFi Suite Built on Multi-EVM Blockchain',
    description: '',
    images: [{ url: '/hero.jpg' }],
  },
}
