import BaseChain from '../type'

export const bscTestnet: BaseChain = {
  id: 97,
  name: 'Binance Smart Chain Testnet',
  features: ['swap', 'cross-swap'],

  network: 'BSCTestnet',
  rpcUrls: {
    default: {
      http: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    },
  },
  blockExplorers: {
    default: {
      name: 'BinanceScan',
      url: 'https://testnet.bscscan.com/',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Smart Chain Testnet',
    symbol: 'tBNB',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 0,
    },
  },
  swap: {
    factoryAddress: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
    initCodeHash: '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66',
    routerAddress: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  },
  testnet: true,
  wrappedNative: {
    address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    decimals: 18,
    symbol: 'WBNB',
    name: 'Wrapped BNB',
  },
  stableToken: {
    address: '0x55d398326f99059fF775485246999027B3197955',
    decimals: 18,
    symbol: 'USDT',
    name: 'Tether USD',
  },
  smartRouterAddress: '0x',
}
