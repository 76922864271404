import BaseChain from '../type'

export const goerli: BaseChain = {
  id: 5,
  name: 'Goerli',
  network: 'GOERLI',
  features: ['cross-swap'],

  nativeCurrency: { name: 'Goerli Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/eth_goerli'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://goerli.etherscan.io',
      apiUrl: 'https://api-goerli.etherscan.io/api',
    },
  },
  contracts: {
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    },
    ensUniversalResolver: {
      address: '0xfc4AC75C46C914aF5892d6d3eFFcebD7917293F1',
      blockCreated: 10_339_206,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 6507670,
    },
  },
  testnet: true,
  blockInterval: 6,
  wrappedNative: {
    address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    decimals: 18,
    symbol: 'WETH',
    name: 'Wrapped Ether',
  },
  stableToken: {
    address: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    decimals: 6,
    symbol: 'tUSDC',
    name: 'test USD Coin',
  },
  smartRouterAddress: '0x',
}
