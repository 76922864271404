import { ChainId } from '../chains'

// ADD CHAIN
export const V3_SUBGRAPHS = {
  [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-eth',
  [ChainId.GOERLI]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-goerli',
  [ChainId.BSC]: `https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc`,
  [ChainId.BSC_TESTNET]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-chapel',

  [ChainId.ZETA]: '',
  [ChainId.ZETA_TESTNET]: '',
  [ChainId.ZULU_TESTNET]: '',
  [ChainId.SHAPE_TESTNET]: '',
  // [ChainId.MORPH_TESTNET]: '',
} satisfies Record<ChainId, string | null>

export const V2_SUBGRAPHS = {
  [ChainId.BSC]: 'https://proxy-worker-api.pancakeswap.com/bsc-exchange',
  [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth',
}
