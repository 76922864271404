import { FACTORY_ADDRESS, INIT_CODE_HASH, ROUTER_ADDRESS } from '../common/swap'
import BaseChain from '../type'

export const duckTestnet: BaseChain = {
  id: 202105,
  name: 'DuckChain Testnet',
  features: ['swap'],
  network: 'DUCK',
  rpcUrls: {
    default: {
      http: ['https://testnet-rpc.duckchain.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'DuckScan',
      url: 'https://testnet-scan.duckchain.io',
    },
  },
  nativeCurrency: {
    decimals: 18,
    name: 'The Open Network',
    symbol: 'TON',
  },
  blockInterval: 6,
  contracts: {
    multicall3: {
      address: '0xCd3D31068bD3E2Bac063CD2cEfAFb4b0944b7947',
      blockCreated: 3162643,
    },
    multicall2: {
      address: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
      blockCreated: 3162183,
    },
  },
  testnet: true,
  wrappedNative: {
    address: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6',
    decimals: 18,
    symbol: 'WTON',
    name: 'Wrapped TON',
  },
  platformAddress: '0x874D98E8c6B172A4944554ECa42194ad09836919',
  //   stableToken: {
  //     address: '0x8ACeb2687B59F97Da274FE9135C3fAda0751ecb2',
  //     decimals: 18,
  //     symbol: 'USDT.ETH',
  //     name: 'ZetaChain ZRC20 USDT on ETH',
  //   },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    routerAddress: ROUTER_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
  smartRouterAddress: '0x',
}
