import React from "react";
import styled, { keyframes } from "styled-components";
import { Box } from "../Box";
// import { Image } from "../Image";
import { SpinnerProps } from "./types";

const rotate = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Loader = styled(Box)<SpinnerProps>`
  &.lds-spinner {
    color: var(--primary-color);
    display: inline-block;
    position: relative;
    box-sizing: border-box;

    & > div {
      box-sizing: border-box;
      transform-origin: 40px 40px;
      animation: ${rotate} 1.2s linear infinite;

      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -0.7s;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
        animation-delay: -0.6s;
      }
      &:nth-child(3) {
        transform: rotate(90deg);
        animation-delay: -0.5s;
      }
      &:nth-child(4) {
        transform: rotate(135deg);
        animation-delay: -0.4s;
      }
      &:nth-child(5) {
        transform: rotate(180deg);
        animation-delay: -0.3s;
      }
      &:nth-child(6) {
        transform: rotate(225deg);
        animation-delay: -0.2s;
      }
      &:nth-child(7) {
        transform: rotate(270deg);
        animation-delay: -0.1s;
      }
      &:nth-child(8) {
        transform: rotate(315deg);
        animation-delay: 0s;
      }

      &:after {
        box-sizing: border-box;
        content: " ";
        display: block;
        position: absolute;
        top: 3.2px;
        left: 36.8px;
        width: 7.58px;
        height: 18.96px;
        border-radius: 0;
        background: currentColor;
      }
    }
  }
`;

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 91 }) => {
  const divArray = Array.from({ length: 9 }, (_, index) => <div key={index} />);

  return (
    <Box width={size} height={size} position="relative">
      <Loader width={size} height={size} className="lds-spinner">
        {divArray}
      </Loader>
      {/* <Image
        width={size}
        height={size * 1.197}
        // src="https://assets.pancakeswap.finance/web/pancake-3d-spinner-v2.gif"
        src="/images/loading_abs.gif"
        alt="pancake-3d-spinner"
      /> */}
    </Box>
  );
};

export default Spinner;
