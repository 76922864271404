import { useTranslation } from '@pancakeswap/localization'
import {
  Box,
  Button,
  Flex,
  Text,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuVariant,
  useModal,
} from '@pancakeswap/uikit'
import { DEFAULT_CHAINID } from '@sdk/constants'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
// import NextLink from 'next/link'
import { useDomainNameForAddress } from 'hooks/useDomain'
import { useCallback, useEffect, useState } from 'react'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount, useBalance } from 'wagmi'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
import { formatBigInt } from '@pancakeswap/utils/formatBalance'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import TokenTab from './Token.tab'
import UserAccount from './UserAccount'
import WalletModal, { WalletView } from './WalletModal'
import WalletTransactions from './WalletTransactions'

export enum TabsView {
  TOKEN,
  ACTIVITY,
}

const TabsButtons = () => {
  return <></>
}

const UserMenuItems = () => {
  const { t } = useTranslation()
  const { isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions } = usePendingTransactions()
  const { account, chainId, chain } = useActiveWeb3React()
  const [view, setView] = useState(TabsView.TOKEN)

  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  // const [onPresentCrossTransactionModal] = useModal(<WalletModal initialView={WalletView.CROSS_TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

  const onClickWalletMenu = useCallback((): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }, [isWrongNetwork, onPresentWalletModal, onPresentWrongNetworkModal])

  const isDefaultChainId = chainId === DEFAULT_CHAINID

  const defaultBalance = useBalance({
    address: account,
    query: {
      enabled: !isDefaultChainId,
    },
  })

  const handleChangeTab = (id: TabsView) => setView(id)

  return (
    <>
      <Box padding="20px">
        <UserAccount onClick={logout} mb="12px" />
        {/* <UserMenuDivider /> */}
        {/* <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem> */}
        {/* <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentCrossTransactionModal}>
        {t('Cross-swap Transaction')}
        {hasPendingTransactions && <RefreshIcon spin />}
      </UserMenuItem> */}
        <Text fontSize="30px" fontWeight="600" mb="12px">
          ${formatBigInt(defaultBalance?.data?.value ?? 0n, 6)}
        </Text>

        <UserMenuDivider />

        <Flex width="100%" mt="16px">
          <Button
            width="100%"
            variant={view === TabsView.TOKEN ? 'primary' : 'text'}
            onClick={() => handleChangeTab(TabsView.TOKEN)}
          >
            Token
          </Button>
          <Button
            width="100%"
            variant={view === TabsView.ACTIVITY ? 'primary' : 'text'}
            onClick={() => handleChangeTab(TabsView.ACTIVITY)}
          >
            Activity
          </Button>
        </Flex>

        <Box py="16px">
          {view === TabsView.TOKEN ? (
            <>
              <TokenTab />
            </>
          ) : null}
          {view === TabsView.ACTIVITY ? (
            <>
              <WalletTransactions />
            </>
          ) : null}
        </Box>

        {/* <NextLink href={`/profile/${account?.toLowerCase()}`} passHref>
        <UserMenuItem disabled={isWrongNetwork || chainId !== ChainId.BSC}>{t('Your NFTs')}</UserMenuItem>
      </NextLink> */}
        {/* <ProfileUserMenuItem
        isLoading={isLoading}
        hasProfile={hasProfile}
        disabled={isWrongNetwork || chainId !== ChainId.BSC}
        /> 
        <UserMenuDivider />
      */}
        {/* <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem> */}
      </Box>
    </>
  )
}

const UserMenu = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { domainName, avatar } = useDomainNameForAddress(account ?? '')
  const { isWrongNetwork } = useActiveChainId()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { profile } = useProfile()
  const avatarSrc = profile?.nft?.image?.thumbnail ?? avatar
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  if (account) {
    return (
      <UIKitUserMenu
        account={domainName || account}
        ellipsis={!domainName}
        avatarSrc={avatarSrc}
        text={userMenuText}
        variant={userMenuVariable}
      >
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : <></>)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : <></>)}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton scale="md" style={{ border: '1px solid white' }}>
      <Box display={['none', 'none', 'none', 'flex']} style={{ alignItems: 'center' }}>
        {/* <WalletIcon width={28} height={28} mr="8px" /> */}
        <Text color="white" bold>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Box>
      <Box display={['block', 'block', 'block', 'none']}>
        <Text color="white">
          <Trans>Connect</Trans>
        </Text>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
